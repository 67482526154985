import { editSystemConfig, uploadFile, systemConfig } from "@/api/api";
import editor from "@/components/editor.vue";
import editor1 from "@/components/editor1.vue";
export default {
  name: "index",
  components: {
    editor
  },
  props: ['Row'],
  data() {
    return {
      Info: {
        id: '',
        goods_js_pdf: '',
        goods_rule_pdf: '',
        service_agreement: '',
        privacy_agreement: ''
      }
    };
  },
  mounted() {
    this.systemConfigInfo();
  },
  methods: {
    beforeAvatarUpload(e, name) {
      let formData = new FormData();
      let fileName = e.name;
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          this.Info[name] = res.data;
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    },
    systemConfigInfo() {
      systemConfig().then(res => {
        if (res.data) {
          this.Info = res.data;
          this.setText();
        }
      });
    },
    subData() {
      //提交
      editSystemConfig(this.Info).then(res => {
        this.$root.msgResult(res, '/admin/sysConfig');
      });
    },
    handleAvatarSuccess(e) {
      //图片上传成功后
      this.Info.businessLicenseImg = e.data;
    },
    setText() {
      this.$refs.editor.setText(this.Info.service_agreement);
      this.$refs.editor1.setText(this.Info.privacy_agreement);
    },
    getText(html) {
      this.Info.service_agreement = html;
    },
    getText1(html) {
      this.Info.privacy_agreement = html;
    }
  }
};